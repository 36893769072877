import React, { useState } from "react";
import { GoThreeBars } from "@react-icons/all-files/go/GoThreeBars";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import "./Responsive.scss";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import Search from "../Search/Search";
import { Link } from "gatsby";
import { BlackLogo } from "../../svg";

const Responsive = ({ opener }) => {
  const handleClick = () => {
    opener(true);
  };
  const [search, setSearch] = useState(false);
  const handleChanger = () => {
    setSearch(!search);
  };
  return (
    <>
      <div className="cs-header__inner cs-header__inner-mobile pt-2 ms-3">
        <div className="row">
          <div className="col-4 cs-header__col cs-col-left ps-0">
            <span className="cs-header__offcanvas-toggle " role="button">
              <i className="cs-icon cs-icon-menu">
                <GoThreeBars className="icon-color" onClick={handleClick} />
              </i>
            </span>
          </div>
          <div className="col-4 cs-header__col cs-col-center">
            <div className="cs-logo">
              <Link
                className="cs-header__logo cs-logo-once "
                to="/"
              >
                <div className="mb-header-logo">
                  <BlackLogo />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-4 cs-header__col cs-col-right d-flex">
            <div className="theme-changer pt-3">
              <ThemeToggle />
            </div>
            <span className="cs-header__search-toggle" role="button">
              <i className="cs-icon cs-icon-search">
                <BiSearch
                  className="responsive-icon-search"
                  onClick={handleChanger}
                />
              </i>
            </span>
          </div>
        </div>
      </div>
      <Search searcher={search} setSearch={setSearch} />
    </>
  );
};
export default React.memo(Responsive);
