import React from "react";
import { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import { BsBrightnessHigh } from "@react-icons/all-files/bs/BsBrightnessHigh";
import { FiMoon } from "@react-icons/all-files/fi/FiMoon";
interface Size {
  width: number | undefined;
}
const ThemeToggle = () => {
  const size: Size = useWindowSize();
  function useWindowSize(): Size {
    const [windowSize, setWindowSize] = useState<Size>({
      width: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  if (size.width > 1200) {
    return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <AntSwitch
            checked={theme === "dark"}
            onClick={(e) =>
              toggleTheme(
                (e.target as HTMLInputElement).checked ? "dark" : "light"
              )
            }
            name="checked"
          />
        )}
      </ThemeToggler>
    );
  } else {
    return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <div className="toggle-theme">
            {theme == "dark" && (
              <div className="cs-header__scheme-toggle-icon cs-icon cs-icon-sun">
                <BsBrightnessHigh
                  className="responsive-icon-sun"
                  onClick={(e) =>
                    toggleTheme(
                      (e.target as HTMLInputElement).checked ? "dark" : "light"
                    )
                  }
                />
              </div>
            )}
            {theme == "light" && (
              <div
                className="cs-header__scheme-toggle-icon cs-icon cs-icon-moon"
                onClick={(e) =>
                  toggleTheme(
                    (e.target as HTMLInputElement).checked ? "light" : "dark"
                  )
                }
              >
                <FiMoon className="responsive-icon-moon" />
              </div>
            )}
          </div>
        )}
      </ThemeToggler>
    );
  }
};
export default (ThemeToggle);
